import store from '@/store';
export const READ_BOOKING = 'bookings:read';
export const WRITE_BOOKING = 'bookings:write';

export function can(permission) {

    const ability = store.state.User.user.permissions || [];

    return ability.indexOf(permission) !== -1;
}
