import { getUser, logout } from '@/api/auth';

export const UserModule = {
    namespace: true,

    state: {
        user: {}
    },

    getters: {
        user: (state) => state.user
    },

    mutations: {

        SET_USER(state, user) {
            state.user = user;
        },

        DELETE_USER(state) {
            state.user = {};
        }
    },

    actions: {

        async setUser({ commit}) {

            const response = await getUser();
            if(response.status === 200) {
                commit('SET_USER', response.data.data);
            }
        },

        async deleteUser({ commit }) {
            await logout();
            localStorage.clear();
            commit('DELETE_USER');
        }
    },
}