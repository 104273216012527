<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <a href="https://www.webscope.io/" target="_blank">
          <v-img
            alt="Transformative Logo"
            class="shrink mr-2"
            contain
            :src="logo"
            transition="scale-transition"
          />
        </a>
        <router-link :to="{name: 'Home'}">
          <v-toolbar-title class="white--text">Room Booker</v-toolbar-title>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <router-link v-if="user.id" :to="{name: 'Booking'}" class="white--text mx-2">Booking</router-link>
      <a v-if="user.id" @click="logout" class="white--text mx-2">Logout</a>
      <router-link v-else :to="{name: 'Login'}" class="white--text mx-2">Login</router-link>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import logo from '@/assets/transformative.png';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',

  data: () => ({
    logo
  }),

  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    ...mapActions(['deleteUser']),

    logout() {
      this.deleteUser().then(() => {
        this.$router.push({name: 'Home'});
      })
    }
  }

};
</script>
