import request from '@/api/request';

export function token(payload) {

    return request({
        url: 'token',
        method: 'POST',
        data: payload
    });
}

export function getUser() {

    return request({
        url: 'user',
        method: 'GET'
    });
}

export function logout() {
    return request({
        url: 'token',
        method: 'DELETE'
    });
}
