import request from '@/api/request';

export function getRooms() {

    return request({
        url: 'rooms',
        method: 'GET',
    });
}

export function getAttenders() {

    return request({
        url: 'users',
        method: 'GET',
    });
}

export function booking(payload) {

    return request({
        url: 'bookings',
        method: 'POST',
        data: payload
    });
}

export function getBookings(params = {}) {
    return request({
        url: 'bookings',
        method: 'GET',
        params: params
    });
}