import Vue from 'vue';
import Vuex from 'vuex';
import { UserModule } from "@/store/UserModule";
import {SessionModule} from "@/store/SessionModule";
import {BookingModule} from "@/store/BookingModule";

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    User: UserModule,
    Session: SessionModule,
    Booking: BookingModule
  }
})
