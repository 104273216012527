<template>
  <transformative />
</template>

<script>

  import Transformative from "../components/Transformative";
  export default {
    name: 'Home',

    components: {
      Transformative
    },
  }
</script>
