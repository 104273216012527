import { token } from '@/api/auth';

export const SessionModule = {
    namespace: true,

    state: {
        token: null
    },

    getters: {
        token: (state) => state.token
    },

    mutations: {

        SET_TOKEN(state, token) {
            state.token = token;
        },

        DELETE_TOKEN(state) {
            state.token = null;
        }
    },

    actions: {

        async setToken({ commit}, token ) {
            commit('SET_TOKEN', token);
        },

        async deleteToken({ commit }) {
            commit('DELETE_TOKEN');
        }
    },
}