<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="logo"
            class="my-5 mx-auto"
            width="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Room Booker
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from '@/assets/transformative.png';
export default {

  name: 'Transformative',

  data() {
    return {
      logo
    }
  }
}
</script>