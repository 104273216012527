import { getBookings } from '@/api/booking';

export const BookingModule = {
    namespace: true,

    state: {
        bookings: []
    },

    getters: {
        bookings: (state) => state.bookings
    },

    mutations: {

        SET_BOOKINGS(state, bookings) {
            state.bookings = bookings;
        }
    },

    actions: {

        async setBookings({ commit}, params = {}) {

            const response = await getBookings(params);
            if(response.status === 200) {
                commit('SET_BOOKINGS', response.data.data);
            }
        }
    },
}