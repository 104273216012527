import axios from 'axios';
//import store from '@/store';
import router from '@/router';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const request = axios.create({
    baseURL: process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_API_URL,
    timeout: 60 * 1000, // Timeout
    withCredentials: false, // Check cross-site Access-Control
});

request.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

request.interceptors.response.use(
    null,
    error => {
        if(error.response.status === 401) {
            router.push({name: 'Login'});
        }
        else if(error.response.status === 404) {
            alert('Please check .env.local file if "VUE_APP_API_URL" is set to the Backend service');
        }
        else {
            return error.response;
        }
    }
);

export default request;